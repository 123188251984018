<template>
  <div class="animated fadeIn">
    <div>
      <h4 class="mb-3">
        Транспортное средство ID{{ transportVehiclesId }}
        <span
          v-if="form.isCancel"
          class="text-danger"
        > (пометка на удаление)</span>
        <span v-else>
          <span
            v-if="form.status === 0"
            class="text-secondary"
          > (заблокирован СБ) </span>
          <span
            v-if="form.status === 1"
            class="text-success"
          > (допущен) </span>
          <span
            v-if="form.status === 2"
            class="text-warning"
          > (на верификации) </span>
          <span
            v-if="form.status === 3"
            class="text-danger"
          > (не допущен) </span>
          <span
            v-if="form.status === 4"
            class="text-primary"
          > (проверка логистом) </span>
          <span
            v-if="form.status === 5"
            class="text-danger"
          > (отклонен) </span>
          <span
            v-if="blockedSecurity"
            class="badge badge-danger"
          >Блокировка СБ</span>
        </span>
      </h4>
      <p
        v-if="form.customerComment && form.status === 5"
        class="text-danger"
      >
        Причина отклонения: "{{ form.customerComment }}"
      </p>
      <p
        v-else-if="form.customerComment && form.status === 0"
        class="text-danger"
      >
        Причина возврата на редактирование: "{{ form.customerComment }}"
      </p>
      <div class="form-sections">
        <div class="custom-fieldset">
          <h5 class="custom-fieldset__title">
            Свидетельство о регистрации автомобиля (СТС)
          </h5>
          <b-row>
            <b-col md="6">
              <b-form-group
                id="seriesSTSInputGroup1"
                label="Серия и номер СТС"
                label-for="sts"
              >
                <b-form-input
                  id="sts"
                  v-model.trim="form.STS"
                  disabled
                  type="text"
                  name="sts"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="stsScreenInputGroup3"
                label="Лицевая сторона СТС"
                label-for="sts-screen"
              >
                <div
                  v-if="form.stsScreen.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.stsScreen.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.stsScreen.file)"
                  >{{ form.stsScreen.file.originalName }}</a>
                </div>
                <div
                  v-else
                  class="mt-2"
                >
                  Файл не был добавлен
                </div>
              </b-form-group>
              <b-form-group
                id="stsScreenInputGroup4"
                label="Обратная сторона СТС"
                label-for="sts-screen-back"
              >
                <div
                  v-if="form.stsScreenBack.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.stsScreenBack.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.stsScreenBack.file)"
                  >{{ form.stsScreenBack.file.originalName }}</a>
                </div>
                <div
                  v-else
                  class="mt-2"
                >
                  Файл не был добавлен
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div class="custom-fieldset">
          <h5 class="custom-fieldset__title">
            Данные по транспортному средству
          </h5>
          <b-row>
            <b-col md="6">
              <b-form-group
                id="vehicleMakeInputGroup4"
                class="form-group-el-select"
                label="Марка ТС"
                label-for="vehicle-make"
              >
                <el-select
                  v-model="form.vehicleMake.value"
                  class="form-control"
                  name="vehicle-make"
                  placeholder="Начните вводить название марки"
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :loading="loading"
                  :disabled="true"
                >
                  <el-option
                    v-for="item in form.vehicleMake.options"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="registrationNumberInputGroup5"
                label="Государственный регистрационный номер (ГРН)"
                label-for="registration-number"
              >
                <b-form-input
                  id="registration-number"
                  v-model.trim="form.registrationNumber"
                  disabled
                  type="text"
                  name="registration-number"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="form.vehicleType.id !== vehicleTypeWithOutBodyId"
              md="3"
            >
              <b-form-group
                id="bodyVolumeInputGroup6"
                label="Объем, т"
                label-for="body-volume"
              >
                <b-form-input
                  id="body-volume"
                  v-model.trim="form.bodyVolume"
                  disabled
                  type="number"
                  name="body-volume"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="form.vehicleType.id !== vehicleTypeWithOutBodyId"
              md="3"
            >
              <b-form-group
                id="bodyWidthInputGroup7"
                label="Ширина кузова"
                label-for="body-width"
              >
                <b-form-input
                  id="body-width"
                  v-model.trim="form.bodyWidth"
                  disabled
                  type="number"
                  name="body-width"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="form.vehicleType.id !== vehicleTypeWithOutBodyId"
              md="3"
            >
              <b-form-group
                id="bodyHeightInputGroup8"
                label="Высота кузова"
                label-for="body-height"
              >
                <b-form-input
                  id="body-height"
                  v-model.trim="form.bodyHeight"
                  disabled
                  type="number"
                  name="body-height"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="form.vehicleType.id !== vehicleTypeWithOutBodyId"
              md="3"
            >
              <b-form-group
                id="bodyDepthInputGroup9"
                label="Глубина кузова"
                label-for="body-depth"
              >
                <b-form-input
                  id="body-depth"
                  v-model.trim="form.bodyDepth"
                  disabled
                  type="number"
                  name="body-depth"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="form.vehicleType.id !== vehicleTypeWithOutBodyId"
              md="3"
            >
              <b-form-group
                id="weightAllowedInputGroup10"
                label="Грузоподъемность, т"
                label-for="weight-allowed"
              >
                <b-form-input
                  id="weight-allowed"
                  v-model.trim="form.weightAllowed"
                  disabled
                  type="number"
                  name="weight-allowed"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="vehicleTypeGroup10"
                class="form-group-el-select"
                label="Тип ТС"
                label-for="vehicle-type"
              >
                <el-select
                  id="vehicle-type"
                  v-model="form.vehicleType.id"
                  class="form-control"
                  name="vehicle-type"
                  placeholder=""
                  clearable
                  :loading="loading"
                  :disabled="true"
                >
                  <el-option
                    v-for="item in vehicleTypes"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                v-if="form.vehicleType.id !== vehicleTypeWithOutBodyId"
                id="bodyTypeGroup11"
                class="form-group-el-select"
                label="Тип кузова"
                label-for="body-type"
              >
                <el-select
                  id="body-type"
                  v-model="form.bodyType.id"
                  class="form-control"
                  name="body-type"
                  placeholder=""
                  clearable
                  :loading="loading"
                  :disabled="true"
                >
                  <el-option
                    v-for="item in bodyTypes"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div class="custom-fieldset">
          <h5 class="custom-fieldset__title">
            Владелец транспортного средства
          </h5>
          <b-row>
            <b-col md="12">
              <b-form-group id="isOwnGroup12">
                <b-form-checkbox
                  id="is-own"
                  v-model="isOwn"
                  disabled
                  name="is-own"
                >
                  Собственное ТС
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col
              v-if="!isOwn"
              md="12"
            >
              <b-form-group
                id="contractorInputGroup5"
                class="form-group-el-select"
                label="Владелец"
                label-for="contractor"
              >
                <el-select
                  v-model="owner.value"
                  class="form-control"
                  name="owner"
                  placeholder="Начните вводить название организации"
                  disabled
                >
                  <el-option
                    v-for="item in owner.options"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div class="custom-fieldset">
          <h5 class="custom-fieldset__title">
            Водитель выполняющий рейсы на данном ТС
          </h5>
          <p>Укажите водителя, который будет выполнять рейсы на данном траспортном средстве.</p>
          <div class="custom-fieldset">
            <b-row>
              <b-col md="12">
                <b-form-group class="form-group-el-select">
                  <el-select clearable 
                  :value="contractorDriver"
                  class="form-control"
                  name="responsible"
                  filterable
                  :disabled="true"
                  >
                  </el-select>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div class="fixed-buttons">
        <b-button
          type="button"
          variant="secondary"
          class="mr-2"
          :class="{'loading' : loading}"
          @click="$router.go(-1)"
        >
          Назад
        </b-button>
        <b-button
          v-if="$store.state.user.ownerType === 'contractor' && $store.state.user.ownerId === Number(contractorId) && form.status === 0 && $store.getters.isAdmin"
          type="button"
          variant="success"
          class="mr-2"
          :class="{'loading' : loading}"
          :disabled="loading"
          @click="contractorsTransportVehicleSendToCheck()"
        >
          Отправить на проверку
        </b-button>
        <b-button
          v-if="!isCancel && $store.state.user.ownerType === 'contractor' && $store.state.user.ownerId === Number(contractorId) && form.status === 0 && $store.getters.isAdmin"
          :href="'/transport-vehicles/vehicle/id' + transportVehiclesId"
          variant="primary"
          class="mr-2"
          :class="{'loading' : loading}"
          :disabled="loading"
        >
          Редактировать
        </b-button>
        <b-button
          v-if="!isCancel && $store.state.user.ownerType === 'contractor' && $store.state.user.ownerId === Number(contractorId) && (form.status === 0 || form.status === 1 || form.status === 3 || form.status === 5) && $store.getters.isAdmin"
          type="button"
          variant="danger"
          :class="{'loading' : loading}"
          :disabled="loading"
          @click="confirmDeleteVehicle"
        >
          Удалить
        </b-button>
        <b-button
          v-if="$store.state.user.ownerType === 'customer' && form.status === 4"
          type="button"
          variant="success"
          class="mr-2"
          :class="{'loading' : loading}"
          :disabled="loading"
          @click="customersContractorsTransportVehicleToVerification()"
        >
          Отправить на верификацию в СБ
        </b-button>
        <b-button
          v-if="$store.state.user.ownerType === 'customer' && form.status === 4"
          type="button"
          variant="primary"
          class="mr-2"
          :class="{'loading' : loading}"
          :disabled="loading"
          @click="confirmReturnVehicle()"
        >
          Вернуть на редактирование
        </b-button>
        <b-button
          v-if="$store.state.user.ownerType === 'customer' && form.status === 4"
          type="button"
          variant="danger"
          :class="{'loading' : loading}"
          :disabled="loading"
          @click="confirmRefusalVehicle()"
        >
          Отклонить
        </b-button>
      </div>
    </div>
    <b-modal
      v-model="confirmReturnVehicleModal.show"
      :title="confirmReturnVehicleModal.title"
      class="modal-warning"
      cancel-title="Назад"
      ok-title="Отправить"
      @ok="returnVehicle()"
    >
      <p>{{ confirmReturnVehicleModal.desc }}</p>
      <b-form-textarea
        id="comment"
        v-model="confirmReturnVehicleModal.comment"
        type="text"
        name="comment"
        rows="4"
        :placeholder="confirmReturnVehicleModal.placeholder"
        autocomplete="comment"
        no-resize
      />
    </b-modal>
    <b-modal
      v-model="confirmRefusalVehicleModal.show"
      :title="confirmRefusalVehicleModal.title"
      class="modal-warning"
      cancel-title="Назад"
      ok-title="Отправить"
      @ok="refusalVehicle()"
    >
      <p>{{ confirmRefusalVehicleModal.desc }}</p>
      <b-form-textarea
        id="comment"
        v-model="confirmRefusalVehicleModal.comment"
        type="text"
        name="comment"
        rows="4"
        :placeholder="confirmRefusalVehicleModal.placeholder"
        autocomplete="comment"
        no-resize
      />
    </b-modal>
  </div>
</template>

<script>
import notifications from '../../components/mixins/notifications';
import {
  contractorsTransportVehicleRead,
  vehicleModelsTypesList,
  vehiclesModelRead,
  vehicleModelsBodyTypesList,
  contractorsTransportVehicleSendToCheck,
  contractorsTransportVehicleDelete,
  customersContractorsTransportVehicleToVerification,
  customersContractorsTransportVehicleToNew,
  customersContractorsTransportVehicleToCancel,
  downloadFile,
} from '../../services/api';

export default {
  name: 'TransportVehiclesEdit',
  mixins: [notifications],
  props: ['contractorId', 'transportVehiclesId'],
  data() {
    return {
      checkVehicleDone: true,
      existingVehicle: true,
      contractorDriver: '',
      vehicleTypes: [{value: null, text: 'Тип ТС'}],
      bodyTypes: [{value: null, text: 'Тип кузова'}],
      vehicleTypeWithOutBodyId: null,
      blockedSecurity: false,
      isCancel: false,
      isOwn: false,
      owner: {
        options: [],
        value: '',
      },
      form: {
        status: null,
        STS: '',
        stsScreen: {},
        stsScreenBack: {},
        vehicleMake: {
          options: [],
          value: '',
        },
        registrationNumber: '',
        bodyVolume: '',
        weightAllowed: '',
        bodyHeight: '',
        bodyWidth: '',
        bodyDepth: '',
        vehicleType: {
          id: null,
        },
        bodyType: {
          id: null,
        },
        customerComment: null,
      },
      confirmReturnVehicleModal: {
        show: false,
        title: '',
        desc: '',
        label: '',
        placeholder: '',
        comment: '',
      },
      confirmRefusalVehicleModal: {
        show: false,
        title: '',
        desc: '',
        label: '',
        placeholder: '',
        comment: '',
      },
      loading: false,
    };
  },
  watch: {
    '$route'() {
      this.contractorsTransportVehicleRead(this.contractorId, this.transportVehiclesId);
    },
  },
  mounted() {
    this.vehicleModelsTypesList();
    this.vehicleModelsBodyTypesList();
    this.contractorsTransportVehicleRead(this.contractorId, this.transportVehiclesId);
  },
  methods: {
    downloadFile,
    async getVehicleModelData(modelId) {
      this.loading = true;
      if (modelId) {
        const response = await vehiclesModelRead(modelId);
        if (response && response.status === 200) {
          this.form.bodyVolume = response.data.bodyVolume;
          this.form.weightAllowed = response.data.loadCapacity;
          this.form.bodyHeight = response.data.bodyHeight;
          this.form.bodyWidth = response.data.bodyWidth;
          this.form.bodyDepth = response.data.bodyDepth;
          this.form.vehicleType.id = response.data.type.id;
        }
      }
      this.loading = false;
    },
    onVehicleMakeChange(value) {
      this.getVehicleModelData(value);
    },
    async contractorsTransportVehicleRead(contractorId, transportVehicleId) {
      this.loading = true;
      const response = await contractorsTransportVehicleRead(contractorId, transportVehicleId);
      if (response && response.status === 200) {
        if (response.data.contractorDriver) {
          let text = null;
          if (response.data.contractorDriver.driver.patronymic !== undefined) { 
            text = `${response.data.contractorDriver.driver.surname} ${response.data.contractorDriver.driver.name} ${response.data.contractorDriver.driver.patronymic}`;
          } else {
            text = `${response.data.contractorDriver.driver.surname} ${response.data.contractorDriver.driver.name}`;
          }
          this.contractorDriver = text;
        }
        this.isOwn = response.data.isOwn;
        this.blockedSecurity = response.data.vehicle.blockedSecurity;
        this.isCancel = response.data.vehicle.isCancel;
        this.form.status = response.data.vehicle.status;
        this.form.STS = response.data.vehicle.certificateRegistrationVehicleSeriesNumber;
        if (response.data.stsScreen) {
          this.form.stsScreen = response.data.stsScreen;
        }
        if (response.data.stsScreenBack) {
          this.form.stsScreenBack = response.data.stsScreenBack;
        }
        this.form.vehicleMake.options = [];
        this.form.vehicleMake.options.push({
          value: response.data.vehicle.model.id,
          text: response.data.vehicle.model.name + (response.data.vehicle.model.containerTransportVehicle ? ' (контейнеровоз)':''),
        });
        this.form.vehicleMake.value = response.data.vehicle.model.id;
        this.onVehicleMakeChange(response.data.vehicle.model.id);
        this.form.registrationNumber = response.data.vehicle.registrationNumber;
        if (response.data.vehicle.bodyType) {
          this.form.bodyType.id = response.data.vehicle.bodyType.id;
        }
        if (response.data.vehicle.contractorOwner) {
          this.owner.options.push({
            value: response.data.vehicle.contractorOwner.id,
            text: response.data.vehicle.contractorOwner.name,
          });
          this.owner.value = response.data.vehicle.contractorOwner.id;
        }
        if (response.data.customerComment) {
          this.form.customerComment = response.data.customerComment;
          this.confirmRefusalDriverModal.comment = response.data.customerComment;
          this.confirmReturnDriverModal.comment = response.data.customerComment;
        }
      }
      this.loading = false;
    },
    async vehicleModelsTypesList(params) {
      this.loading = true;
      const response = await vehicleModelsTypesList(params);
      if (response && response.status === 200) {
        this.vehicleTypes = this.vehicleTypes.concat(
          response.data.items.map((item) => {
            if (item.name === 'Тягач') {
              this.vehicleTypeWithOutBodyId = item.id;
            }
            return {value: item.id, text: item.name};
          }),
        );
      }
      this.loading = false;
    },
    async vehicleModelsBodyTypesList() {
      this.loading = true;
      const response = await vehicleModelsBodyTypesList({
        for_delivery: false,
        limit: 100,
      });
      if (response && response.status === 200) {
        this.bodyTypes = this.bodyTypes.concat(
          response.data.items.map((item) => {
            return {value: item.id, text: item.name};
          }),
        );
      }
      this.loading = false;
    },
    async contractorsTransportVehicleSendToCheck() {
      this.loading = true;
      const response = await contractorsTransportVehicleSendToCheck(this.contractorId, this.transportVehiclesId);
      if (response && response.status === 200) {
        this.showSuccess('Транспортное средство отправлено на первичную проверку');
        this.$router.go();
      }
      this.loading = false;
    },
    confirmDeleteVehicle() {
      this.$bvModal
        .msgBoxConfirm('Вы уверены что хотите удалить транспортное средство?', {
          okTitle: 'Да',
          cancelTitle: 'Назад',
        })
        .then((confirm) => {
          if (confirm) {
            this.deleteVehicle();
          }
        });
    },
    async deleteVehicle() {
      this.loading = true;
      const response = await contractorsTransportVehicleDelete(this.contractorId, this.transportVehiclesId);
      if (response && response.status === 200) {
        this.showSuccess('Транспортное средство удалено');
        this.$router.push({path: '/transport-vehicles'});
      }
      this.loading = false;
    },
    async returnVehicle() {
      this.loading = true;
      const response = await customersContractorsTransportVehicleToNew(this.contractorId, this.transportVehiclesId, this.confirmReturnVehicleModal.comment);
      if (response && response.status === 200) {
        this.showSuccess('Транспортное средство возвращено на редактирование');
        this.$router.go();
      }
      this.loading = false;
    },
    confirmReturnVehicle() {
      this.confirmReturnVehicleModal = {
        show: true,
        title: 'Возврат транспортного средства на редактирование',
        desc: 'Для того, чтобы вернуть транспортное средство на редактирование, необходимо указать причину возврата. Пожалуйста, опишите ее ниже.',
        placeholder: 'Укажите причину',
      };
    },
    async refusalVehicle() {
      this.loading = true;
      const response = await customersContractorsTransportVehicleToCancel(this.contractorId, this.transportVehiclesId, this.confirmRefusalVehicleModal.comment);
      if (response && response.status === 200) {
        this.showSuccess('Транспортное средство отклонено');
        this.$router.go();
      }
      this.loading = false;
    },
    confirmRefusalVehicle() {
      this.confirmRefusalVehicleModal = {
        show: true,
        title: 'Отклонение транспортного средства',
        desc: 'Для того, чтобы отклонить транспортное средство, необходимо указать причину отклонения. Пожалуйста, опишите ее ниже.',
        placeholder: 'Укажите причину',
      };
    },
    async customersContractorsTransportVehicleToVerification() {
      this.loading = true;
      const response = await customersContractorsTransportVehicleToVerification(this.contractorId, this.transportVehiclesId);
      if (response && response.status === 200) {
        this.showSuccess('Транспортное средство отправлено на верификацию в СБ');
        this.$router.go();
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
</style>
